<template>
    <div>
        <div class="content-main">
            <div class="content-header">
                <h2>卡组详情</h2>
            </div>
            <div>
                <el-divider></el-divider>
            </div>

            <div class="m-center">
                <h4>xxxx比赛 获胜者 xxxx 卡组</h4>
            </div>
            <div class="deck-info">
                <div class="deck-info-item">
                    <div class="deck-title">[卡组ID]</div>
                    <div class="deck-val">11567</div>
                </div>
                <div class="deck-info-item">
                    <div class="deck-title">[分类]</div>
                    <div class="deck-val">VG高阶</div>
                </div>
                <div class="deck-info-item">
                    <div class="deck-title">[禁卡表]</div>
                    <div class="deck-val">2022523</div>
                </div>
                <div class="deck-info-item">
                    <div class="deck-title">[主卡组]</div>
                    <div class="deck-img">
                        <div
                                class="deck-img-item"
                                v-for="item in 50"
                                :key="item"
                        >
                            <div class="d-img">
                                <img src="https://bushiroad.oss-cn-beijing.aliyuncs.com/index/Cad2XO1V2BGaBInSOWiTRQRHn0Xkqavu1652684243.png">
                            </div>
                            <div class="d-count">
                                x1
                            </div>
                        </div>
                    </div>
                </div>
                <div class="m-clear-both"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "detail"
    }
</script>

<style scoped>
    .content-main{
        width: 100%;
        margin: 0 auto;
        background-color: white;
        padding: 20px 0;
    }
    .content-header{
        text-align: center;
    }
    .deck-info{
        width: 98%;
        margin: 0 auto;
        text-align: center;
    }
    .deck-title{
        text-align: center;
        height: 32px;
        line-height: 32px;
        font-weight: bold;
        color: white;
        background-color: #dd1b3e;
        width: 98%;
        margin: 0 auto;
        letter-spacing: 5px;
    }
    .deck-val{
        padding-top: 20px;
        font-weight: bold;
    }
    .deck-info-item{
        margin-bottom: 20px;
        font-size: 16px;
    }
    .deck-img{
        padding-top: 25px;
    }

    .deck-img-item{
        width: 18%;
        margin: 1%;
        height: auto;
        float: left;
        position: relative;
    }
    .d-img{
        width: 100%;
    }
    .d-img >img{
        width: 100%;
    }
    .d-count{
        position: absolute;
        background-color: black;
        height: 25px;
        line-height: 25px;
        width: 100%;
        bottom: 0;
        opacity: 0.80;
        color: white;
        border: white;
        letter-spacing: 5px;
    }

</style>
